import { SisenseDashboard, useTitle } from '@eagle/react-common';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  dashboardId: string;
}

export const Overview: FC<Props> = ({ dashboardId }) => {
  const { t } = useTranslation(['track']);
  useTitle(t('track:page.overview.title'));

  return (
    <SisenseDashboard dashboardId={dashboardId} />
  );
};
